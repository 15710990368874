<template>
  <section
    v-if="sectionEntries.length > 0 && !hideSection"
    :id="section?.custom_id ? section.custom_id : undefined"
    :style="sectionStyles"
    :class="sectionClasses"
  >
    <template v-for="(sectionEntry, i) in sectionEntries" :key="`${i}_comp_dynamic`">
      <ComponentSection
        v-if="sectionEntry"
        :section-entry="sectionEntry.sectionObj"
        :section-key="sectionEntry.sectionKey || ''"
      />
    </template>
  </section>
</template>

<script setup lang="ts">
import { get, compact, isArray, isPlainObject } from 'lodash-es';
import { lightOrDark, hexToRgba } from '~/util/frontend';
interface ISectionEntry {
  sectionObj: {
    [index: string]: any;
  };
  sectionKey: string | undefined;
}

const props = defineProps({
  section: {
    type: Object || undefined,
    required: true,
  },
  renderMode: {
    type: String,
    default: '',
  },
  xxlFullContent: {
    type: Boolean,
    default: false,
  },
  hideSection: {
    type: Boolean,
    default: false,
  },
});

const sectionEntries = computed<ISectionEntry[]>(() => {
  const arr: ISectionEntry[] = [];
  const section = props.section?.section;
  if (!section) return arr;
  for (const key in section) {
    if (Object.prototype.hasOwnProperty.call(section, key)) {
      const sectionComp = section[key];
      const sectionValid = sectionComp !== null;
      const sectionKey = sectionValid ? Object.keys(sectionComp)[0] : undefined;
      const sectionValues = sectionValid ? Object.values(sectionComp) : [];
      // each section entry should have one obj
      let sectionObj: any[] = sectionValues.map((x: any) => {
        if (isPlainObject(x)) {
          if (sectionKey === 'product_highlight' || sectionKey === 'guided_selling_carousel') {
            x.renderMode = props.renderMode;
            x.link_to_pdp = x.link_to_pdp === null ? undefined : x.link_to_pdp;
          }
          return x;
        }
        return null;
      });
      sectionObj = compact(sectionObj)[0];
      if (sectionObj && sectionKey) arr.push({ sectionObj, sectionKey });
    }
  }
  return arr;
});
const backgroundColor = computed(() => {
  const bgHex =
    props.section?.section_background_color ||
    sectionEntries.value.find((x) => x?.sectionObj?.is_hlp_carousel)?.sectionObj.hlp_carousel_background_color;
  return bgHex;
});
const backgroundRgba1 = computed(() => {
  return backgroundColor.value ? hexToRgba(backgroundColor.value, 1) : '';
});
const backgroundRgba0 = computed(() => {
  return backgroundColor.value ? hexToRgba(backgroundColor.value, 0) : '';
});
const sectionStyles = computed(() => {
  return backgroundColor.value ? { backgroundColor: backgroundColor.value } : {};
});
const sectionReferenceClasses = computed(() => {
  const sectionClasses: string[] = [];
  sectionEntries.value.forEach((x) => {
    const ctRef = x.sectionKey === 'Content_Reference';
    const refEdges = get(x, 'sectionObj.referenceConnection.edges');
    if (ctRef && isArray(refEdges)) {
      refEdges.forEach((y: any) => {
        const uid = y?.node?.system?.content_type_uid;
        if (uid) sectionClasses.push(`section-wrapper--${uid}`);
        if (uid && uid.includes('hlp')) sectionClasses.push(`section-wrapper--hlp`);
      });
    }
    if (x.sectionKey === 'product_highlight') {
      sectionClasses.push('section-wrapper--product-highlight');
      if (x.sectionObj?.is_hlp_carousel === true) sectionClasses.push('section-wrapper--hlp-product-carousel');
    }
  });
  return sectionClasses;
});
const sectionClasses = computed(() => {
  let classes: Array<string | unknown> = ['section-wrapper flex-col'];
  if (props.section?.section_background_color && props.section?.section_background_color !== '') {
    classes.push({ 'color-bg': props.section?.section_background_color });
    classes.push(`section-color-theme--${lightOrDark(props.section?.section_background_color)}`);
  }
  if (props.section?.section_layout) classes.push(`section-wrapper--${props.section?.section_layout}`);
  if (sectionEntries.value.length > 1) classes.push('section-wrapper--multi');
  if (sectionReferenceClasses.value.length > 0) classes = classes.concat(sectionReferenceClasses.value);
  return classes;
});
</script>
<style lang="scss" scoped>
.section-wrapper {
  width: 100%;
  section + section,
  + .section-wrapper {
    padding: 2.5rem 0;

    &.section-wrapper--hlp_hero_module,
    &.section-wrapper--hlp_image_module,
    &.section-wrapper--hlp_video_module,
    &.section-wrapper--hlp_product_category_module,
    &.section-wrapper--hlp_category_module,
    &.section-wrapper--hlp_ticker_module {
      padding: 0;
    }

    @include local-mixins.tablet_and_mobile {
      padding: 2rem 0;
    }
  }
  .component-wrapper {
    padding-left: #{local-functions.rem-calc(15)};
    &--content-block,
    &--info-boxes {
      padding-right: #{local-functions.rem-calc(15)};
    }
  }
  &#jebbit-quiz .content-block {
    max-width: initial;
  }
  &.color-bg {
    blockquote {
      text-align: left;
      padding: 0;
    }
    .separator {
      border-color: #000000;
    }
    svg path {
      fill: #000000;
    }
  }
  &--banner,
  &--three_banner,
  &--video {
    .component-wrapper {
      @include local-mixins.desktop_and_tablet {
        padding-left: 0;
      }
    }
    .component-wrapper.component-wrapper--content-reference {
      @include local-mixins.mobile {
        padding-left: 0;
      }
    }
  }
  .component-wrapper--dy-campaign {
    padding-left: 0;
  }
  &--value_prop,
  &--image_content_alternating,
  &--faq {
    .component-wrapper {
      padding-right: #{local-functions.rem-calc(15)};
    }
  }
  &--multi {
    .component-wrapper {
      padding-right: 0;
      margin-bottom: #{local-functions.rem-calc(30)};
      &--content-block,
      &--info-boxes {
        padding-right: #{local-functions.rem-calc(15)};
      }
    }
  }
  &--flush {
    .component-wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &--hlp-product-carousel {
    background-color: v-bind(backgroundRgba1);
    :deep(.outer-wrapper) {
      position: relative;
      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: map.get(local-vars.$zindex, 'base');
        top: 0;
        width: #{local-functions.rem-calc(30)};
        height: 100%;
        @include local-mixins.desktop_and_tablet {
          width: #{local-functions.rem-calc(60)};
        }
      }
      &::before {
        background: linear-gradient(to left, v-bind(backgroundRgba0), v-bind(backgroundRgba1));
        left: 0;
      }
      &::after {
        background: linear-gradient(to right, v-bind(backgroundRgba0), v-bind(backgroundRgba1));
        right: 0;
      }
    }
    .component-wrapper {
      @include local-mixins.desktop_and_tablet {
        padding: #{local-functions.rem-calc(40)} 0;
      }
    }
    :deep(.scroll-wrapper) {
      padding-bottom: #{local-functions.rem-calc(40)};
      @include local-mixins.desktop_and_tablet {
        padding-bottom: #{local-functions.rem-calc(60)};
      }
    }
    :deep(.scroll-container) {
      padding-right: #{local-functions.rem-calc(20)};
    }
  }
}
</style>
